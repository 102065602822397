import React, { Component } from 'react';
import PageWrapper from '../components/Layout/PageWrapper';
import StyledHero from '../components/StyledHero';
import { graphql } from 'gatsby';
import Contact from '../components/Contact/Contact';
import { Col, Container, Row } from 'react-bootstrap';
import Title from '../components/Title';
// import Img from "gatsby-image";
import SEO from "../components/seo";

export default class ContactPage extends Component {
  render() {
    return (
      <PageWrapper>
        <SEO title="Contact" />
        <StyledHero img={this.props.data.contactBcg.childImageSharp.fluid} />
        <Container fluid>
          <Title title="Contact" subtitle="opnemen?" />
          <Container fluid className="py-3" >
          <Row className="d-flex justify-content-center">
            <Col md={8}>
            <Container fluid className="text-center">
            <h5 className="pb-3">Stuur een e-mail naar:</h5>
            <h4 className="pb-3"><a className="a-home" href="mailto:info@stefanipeters.nl">info@stefanipeters.nl</a></h4>
            <h5 className="pb-3">of stuur een berichtje:</h5>
            </Container>
            <Contact url={this.props.location.href} />
            </Col>
          </Row>
          </Container>
        </Container>
      </PageWrapper>
    )
  }
}

export const query = graphql`
{
  contactBcg: file(relativePath: {eq: "contactBcg.jpg"}) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 4160) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  contactSquare: file(relativePath: {eq: "contactSquare.jpg"}) {
    childImageSharp {
      fluid(quality: 90, maxWidth: 4160) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
} 
`