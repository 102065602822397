import React, { useRef, useState } from 'react'
import { Form, Button, Container } from 'react-bootstrap';
// import ReCAPTCHA from "react-google-recaptcha";
// import axios from "axios"
// import * as qs from "query-string"

const Contact = ({ url }) => {
    const domRef = useRef()
    // this.state = { feedbackMsg: null }
    const [feedbackMsg, setFeedbackMsg] = useState(null)

    const submitForm = (ev) => {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            // setFeedbackMsg("Het bericht is verstuurd!")
            window.location.href='/thank-you'
          } else {
            setFeedbackMsg("Er is iets misgegaan. Probeer het alsjeblieft opnieuw.")
          }
        };
        xhr.send(data);
      }

    return (
        <Container fluid>
            <Form
                ref={domRef}
                name="Contact form"
                action="https://formspree.io/f/xayldzeb"
                method="POST"
                onSubmit={event => submitForm(event)}
            >
                <Form.Control type="hidden" name="form-name" value="Contact Form" />

                <Form.Group controlId="validationCustom03">
                    <Form.Label>Naam*</Form.Label>
                    <Form.Control type="text" name="name" required />
                </Form.Group>

                <Form.Group controlId="email">
                    <Form.Label>E-mail*</Form.Label>
                    <Form.Control type="email" name="email" required />
                </Form.Group>

                <Form.Group controlId="message">
                    <Form.Label>Bericht*</Form.Label>
                    <Form.Control as="textarea" rows={10} name="message" required />
                </Form.Group>

                <Form.Group controlId="permission">
                    <Form.Check type="checkbox" label="Ja, ik geef toestemming om mijn gegevens te verzamelen." name="permission" required />
                </Form.Group>

                <Container fluid className="d-flex justify-content-center">
                <Button type="submit" className="btn-blue">
                    Verstuur
                </Button>
                </Container>
            </Form>
            {feedbackMsg && <h5 className="text-center">{feedbackMsg}</h5>}
        </Container>
    )
}

export default Contact;
